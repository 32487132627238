import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretDownIcon,
  CaretUpIcon,
  CrossIcon,
  InfoSignIcon,
  MinusIcon,
  MoreIcon,
  PinIcon,
  PlusIcon,
  ShareIcon,
  UnpinIcon,
  WarningSignIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import Dynamics365Icon from './Dynamics365Icon';
import lib from './library';
import MyobIcon from './MyobIcon';
import NetsuiteIcon from './NetsuiteIcon';
import PayrixIcon from './PayrixIcon';
import QuickbooksIcon from './QuickbooksIcon';
import Svg from './Svg';
import XeroIcon from './XeroIcon';
import LegendIcon from './LegendIcon';

const OfflineIcon = (props) => (
  <Icon name="filled-files-4" size={64} {...props} />
);

export {
  Dynamics365Icon,
  MyobIcon,
  NetsuiteIcon,
  OfflineIcon,
  PayrixIcon,
  QuickbooksIcon,
  XeroIcon,
  LegendIcon,
};

const LogoIcons = {
  xero: XeroIcon,
  quickbooks: QuickbooksIcon,
  myob: MyobIcon,
  payrix: PayrixIcon,
  offline: OfflineIcon,
  dynamics365: Dynamics365Icon,
  netsuite: NetsuiteIcon,
};

const EvergreenIcons = {
  more: MoreIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  pin: PinIcon,
  unpin: UnpinIcon,
  cross: CrossIcon,
  'warning-sign': WarningSignIcon,
  'caret-down': CaretDownIcon,
  'caret-up': CaretUpIcon,
  share: ShareIcon,
  'info-sign': InfoSignIcon,
  'e-arrow-up': ArrowUpIcon,
  'e-arrow-down': ArrowDownIcon,
};

const colors = {
  success: 'green.base',
  danger: 'red.base',
  warning: 'yellow.base',
  disabled: 'neutral.N6',
  info: 'blue.base',
  muted: 'neutral.N8',
};

const renderWithCircle = (name) => (
  <g>
    <mask id={`icon-mask-${name}`}>
      <rect width="100%" height="100%" fill="white" />
      <g transform="scale(0.5) translate(256, 256)" fill="black">
        {lib[name]}
      </g>
    </mask>
    <circle
      mask={`url(#icon-mask-${name})`}
      r="256"
      cx="256"
      cy="256"
      fill="currentColor"
    />
  </g>
);

// Inline function seems to cause re-renders for every parent change. This
// causes some issues with mouse events like click.
const createSvgWithProps = (defaultProps) => (props) => {
  return <Svg {...defaultProps} {...props} />;
};

export const Icon = ({ name, circle, size, color, title, ...props }) => {
  let icon = circle ? renderWithCircle(name) : lib[name];
  const finalColor = color && colors[color] ? colors[color] : color;

  if (typeof icon === 'undefined' && EvergreenIcons[name]) {
    const Component = EvergreenIcons[name];
    const svgProps = {
      ...props,
      color: finalColor,
    };
    return (
      <Component
        is={createSvgWithProps(svgProps)}
        size={typeof size === 'number' ? size : 16}
        title={title}
        {...props}
      />
    );
  }

  if (typeof icon === 'undefined' && LogoIcons[name]) {
    const Component = LogoIcons[name];

    return <Component title={title} color={finalColor} {...props} />;
  }

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 512 512"
      size={size}
      color={finalColor}
      {...props}
    >
      <title>{title || name}</title>
      {icon}
    </Svg>
  );
};

Icon.defaultProps = {
  role: 'img',
  size: 16,
  circle: false,
  ...Svg.defaultProps,
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    ...Object.keys(lib),
    ...Object.keys(EvergreenIcons),
    ...Object.keys(LogoIcons),
  ]).isRequired,
  circle: PropTypes.bool,
  ...Svg.propTypes,
};

// Shortcuts
['close'].forEach(
  (name) =>
    (Icon[name] = ({ name: n, ...props }) => <Icon name={name} {...props} />)
);

Object.assign(Icon, EvergreenIcons);

export default Icon;
